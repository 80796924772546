export const cssFiles = {
    unbundled: {
        dev: {
            common: [
                
                     "public/css/2014/stylesheets/fonts/font-awesome/css/all.min.css",
                
                     "public/css/bootstrap_trimmed.css",
                
                     "public/css/bootstrap_modal.css",
                
                     "public/css/2014/stylesheets/global.css",
                
                     "public/css/animate.css",
                
                     "public/css/emoji.css",
                
                     "public/css/jquery.tagit.css",
                
                     "public/css/tagit.ui-zendesk.css",
                
                     "public/css/foundation_icons_social/social_foundicons.css",
                
                     "public/css/v2.css",
                
                     "public/css/themes.css",
                
                     "public/css/2014/stylesheets/cta.css",
                
                     "public/css/slider.css",
                
                     "public/css/slideshow-control.css",
                
                     "public/css/designer.css",
                
                     "public/css/2014/stylesheets/mobile_market_icons.css",
                
                     "public/css/2014/stylesheets/loading-indicator.css",
                
                     "public/css/v1.css",
                
                     "public/css/waterfall.css",
                
                     "public/css/tooltipster.css",
                
                     "public/css/tooltipster.custom.css",
                
                     "public/css/flickity-2_0_4.min.css",
                
            ],
            
                "hypeTemplate_v2": [  
                    
                        "public/css/2014/stylesheets/hypeTemplate-v2.css",
                    
                ],
            
                "hypeTemplate_photo": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "hypeTemplate_photo_tall": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "hypeTemplate_modern": [  
                    
                        "public/css/2014/stylesheets/modern-theme.css",
                    
                ],
            
                "hypeTemplate_news": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "hypeTemplate_tano": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "hypeTemplate_pin": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "hypeTemplate_inverse": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "hypeTemplate_waterfall": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "hypeTemplate_horizontal": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "hypeTemplate_slideshow": [  
                    
                        "public/css/slideshow.css",
                    
                ],
            
                "hypeTemplate_realtime": [  
                    
                        "public/css/gridpost.css",
                    
                ],
            
                "hypeTemplate_slideshow_qa": [  
                    
                        "public/css/slideshow.css",
                    
                ],
            
                "hypeTemplate_waterfall_events": [  
                    
                        "public/css/waterfall.css",
                    
                ],
            
                "hypeTemplate_advertisingUnit": [  
                    
                        "public/css/2014/stylesheets/advertising-unit-theme.css",
                    
                        "public/css/2014/stylesheets/custom_bootstrap3.css",
                    
                ],
            
                "hypeTemplate": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "artsy_v2": [  
                    
                        "themes/artsy_v2/stylesheets/../../classic_v3/stylesheets/tint-flex.css",
                    
                        "themes/artsy_v2/stylesheets/../../classic_v3/stylesheets/overrides.css",
                    
                        "themes/artsy_v2/stylesheets/main.css",
                    
                ],
            
                "classic_v3": [  
                    
                        "themes/classic_v3/stylesheets/main.css",
                    
                        "themes/classic_v3/stylesheets/tint-flex.css",
                    
                        "themes/classic_v3/stylesheets/overrides.css",
                    
                ],
            
                "gem": [  
                    
                        "themes/gem/stylesheets/main.css",
                    
                ],
            
                "hypeTemplate_atmosphere": [  
                    
                        "themes/hypeTemplate_atmosphere/stylesheets/main.css",
                    
                ],
            
                "hypeTemplate_mosaic": [  
                    
                        "themes/hypeTemplate_mosaic/stylesheets/main.css",
                    
                ],
            
                "new_atmosphere": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "new_classic": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "new_gem": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "new_news": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "new_slider": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "new_square_photo": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "new_tall_photo": [  
                    
                        "public/css/empty.css",
                    
                ],
            
                "news_v2": [  
                    
                        "themes/news_v2/stylesheets/../../classic_v3/stylesheets/tint-flex.css",
                    
                        "themes/news_v2/stylesheets/../../classic_v3/stylesheets/overrides.css",
                    
                        "themes/news_v2/stylesheets/main.css",
                    
                ],
            
                "photo-slideshow": [  
                    
                        "themes/photo-slideshow/stylesheets/main.css",
                    
                ],
            
                "slider_v2": [  
                    
                        "themes/slider_v2/stylesheets/main.css",
                    
                ],
            
                "slideshow_v2": [  
                    
                        "themes/slideshow_v2/stylesheets/main.css",
                    
                        "themes/slideshow_v2/stylesheets/vendor/animate.css",
                    
                ],
            
                "square_photo_v2": [  
                    
                        "themes/square_photo_v2/stylesheets/../../classic_v3/stylesheets/tint-flex.css",
                    
                        "themes/square_photo_v2/stylesheets/../../classic_v3/stylesheets/overrides.css",
                    
                        "themes/square_photo_v2/stylesheets/main.css",
                    
                ],
            
                "testimonials": [  
                    
                        "themes/testimonials/stylesheets/main.css",
                    
                ],
            
                "ticker": [  
                    
                        "themes/ticker/stylesheets/main.css",
                    
                ],
            
        }
    },
    bundled: {
        dev: {
            common: ["public/css/build/dev/tint-common.css"],
            
             "hypeTemplate_v2": [
                 "public/css/build/dev/theme-hypeTemplate_v2.css",
             ], 
            
             "hypeTemplate_photo": [
                 "public/css/build/dev/theme-hypeTemplate_photo.css",
             ], 
            
             "hypeTemplate_photo_tall": [
                 "public/css/build/dev/theme-hypeTemplate_photo_tall.css",
             ], 
            
             "hypeTemplate_modern": [
                 "public/css/build/dev/theme-hypeTemplate_modern.css",
             ], 
            
             "hypeTemplate_news": [
                 "public/css/build/dev/theme-hypeTemplate_news.css",
             ], 
            
             "hypeTemplate_tano": [
                 "public/css/build/dev/theme-hypeTemplate_tano.css",
             ], 
            
             "hypeTemplate_pin": [
                 "public/css/build/dev/theme-hypeTemplate_pin.css",
             ], 
            
             "hypeTemplate_inverse": [
                 "public/css/build/dev/theme-hypeTemplate_inverse.css",
             ], 
            
             "hypeTemplate_waterfall": [
                 "public/css/build/dev/theme-hypeTemplate_waterfall.css",
             ], 
            
             "hypeTemplate_horizontal": [
                 "public/css/build/dev/theme-hypeTemplate_horizontal.css",
             ], 
            
             "hypeTemplate_slideshow": [
                 "public/css/build/dev/theme-hypeTemplate_slideshow.css",
             ], 
            
             "hypeTemplate_realtime": [
                 "public/css/build/dev/theme-hypeTemplate_realtime.css",
             ], 
            
             "hypeTemplate_slideshow_qa": [
                 "public/css/build/dev/theme-hypeTemplate_slideshow_qa.css",
             ], 
            
             "hypeTemplate_waterfall_events": [
                 "public/css/build/dev/theme-hypeTemplate_waterfall_events.css",
             ], 
            
             "hypeTemplate_advertisingUnit": [
                 "public/css/build/dev/theme-hypeTemplate_advertisingUnit.css",
             ], 
            
             "hypeTemplate": [
                 "public/css/build/dev/theme-hypeTemplate.css",
             ], 
            
             "artsy_v2": [
                 "public/css/build/dev/theme-artsy_v2.css",
             ], 
            
             "classic_v3": [
                 "public/css/build/dev/theme-classic_v3.css",
             ], 
            
             "gem": [
                 "public/css/build/dev/theme-gem.css",
             ], 
            
             "hypeTemplate_atmosphere": [
                 "public/css/build/dev/theme-hypeTemplate_atmosphere.css",
             ], 
            
             "hypeTemplate_mosaic": [
                 "public/css/build/dev/theme-hypeTemplate_mosaic.css",
             ], 
            
             "new_atmosphere": [
                 "public/css/build/dev/theme-new_atmosphere.css",
             ], 
            
             "new_classic": [
                 "public/css/build/dev/theme-new_classic.css",
             ], 
            
             "new_gem": [
                 "public/css/build/dev/theme-new_gem.css",
             ], 
            
             "new_news": [
                 "public/css/build/dev/theme-new_news.css",
             ], 
            
             "new_slider": [
                 "public/css/build/dev/theme-new_slider.css",
             ], 
            
             "new_square_photo": [
                 "public/css/build/dev/theme-new_square_photo.css",
             ], 
            
             "new_tall_photo": [
                 "public/css/build/dev/theme-new_tall_photo.css",
             ], 
            
             "news_v2": [
                 "public/css/build/dev/theme-news_v2.css",
             ], 
            
             "photo-slideshow": [
                 "public/css/build/dev/theme-photo-slideshow.css",
             ], 
            
             "slider_v2": [
                 "public/css/build/dev/theme-slider_v2.css",
             ], 
            
             "slideshow_v2": [
                 "public/css/build/dev/theme-slideshow_v2.css",
             ], 
            
             "square_photo_v2": [
                 "public/css/build/dev/theme-square_photo_v2.css",
             ], 
            
             "testimonials": [
                 "public/css/build/dev/theme-testimonials.css",
             ], 
            
             "ticker": [
                 "public/css/build/dev/theme-ticker.css",
             ], 
            
        },

        prod: {
            common: [],
            
             "hypeTemplate_v2": [
                 "public/css/build/prod/tint-hypeTemplate_v2.min.css",
             ], 
            
             "hypeTemplate_photo": [
                 "public/css/build/prod/tint-hypeTemplate_photo.min.css",
             ], 
            
             "hypeTemplate_photo_tall": [
                 "public/css/build/prod/tint-hypeTemplate_photo_tall.min.css",
             ], 
            
             "hypeTemplate_modern": [
                 "public/css/build/prod/tint-hypeTemplate_modern.min.css",
             ], 
            
             "hypeTemplate_news": [
                 "public/css/build/prod/tint-hypeTemplate_news.min.css",
             ], 
            
             "hypeTemplate_tano": [
                 "public/css/build/prod/tint-hypeTemplate_tano.min.css",
             ], 
            
             "hypeTemplate_pin": [
                 "public/css/build/prod/tint-hypeTemplate_pin.min.css",
             ], 
            
             "hypeTemplate_inverse": [
                 "public/css/build/prod/tint-hypeTemplate_inverse.min.css",
             ], 
            
             "hypeTemplate_waterfall": [
                 "public/css/build/prod/tint-hypeTemplate_waterfall.min.css",
             ], 
            
             "hypeTemplate_horizontal": [
                 "public/css/build/prod/tint-hypeTemplate_horizontal.min.css",
             ], 
            
             "hypeTemplate_slideshow": [
                 "public/css/build/prod/tint-hypeTemplate_slideshow.min.css",
             ], 
            
             "hypeTemplate_realtime": [
                 "public/css/build/prod/tint-hypeTemplate_realtime.min.css",
             ], 
            
             "hypeTemplate_slideshow_qa": [
                 "public/css/build/prod/tint-hypeTemplate_slideshow_qa.min.css",
             ], 
            
             "hypeTemplate_waterfall_events": [
                 "public/css/build/prod/tint-hypeTemplate_waterfall_events.min.css",
             ], 
            
             "hypeTemplate_advertisingUnit": [
                 "public/css/build/prod/tint-hypeTemplate_advertisingUnit.min.css",
             ], 
            
             "hypeTemplate": [
                 "public/css/build/prod/tint-hypeTemplate.min.css",
             ], 
            
             "artsy_v2": [
                 "public/css/build/prod/tint-artsy_v2.min.css",
             ], 
            
             "classic_v3": [
                 "public/css/build/prod/tint-classic_v3.min.css",
             ], 
            
             "gem": [
                 "public/css/build/prod/tint-gem.min.css",
             ], 
            
             "hypeTemplate_atmosphere": [
                 "public/css/build/prod/tint-hypeTemplate_atmosphere.min.css",
             ], 
            
             "hypeTemplate_mosaic": [
                 "public/css/build/prod/tint-hypeTemplate_mosaic.min.css",
             ], 
            
             "new_atmosphere": [
                 "public/css/build/prod/tint-new_atmosphere.min.css",
             ], 
            
             "new_classic": [
                 "public/css/build/prod/tint-new_classic.min.css",
             ], 
            
             "new_gem": [
                 "public/css/build/prod/tint-new_gem.min.css",
             ], 
            
             "new_news": [
                 "public/css/build/prod/tint-new_news.min.css",
             ], 
            
             "new_slider": [
                 "public/css/build/prod/tint-new_slider.min.css",
             ], 
            
             "new_square_photo": [
                 "public/css/build/prod/tint-new_square_photo.min.css",
             ], 
            
             "new_tall_photo": [
                 "public/css/build/prod/tint-new_tall_photo.min.css",
             ], 
            
             "news_v2": [
                 "public/css/build/prod/tint-news_v2.min.css",
             ], 
            
             "photo-slideshow": [
                 "public/css/build/prod/tint-photo-slideshow.min.css",
             ], 
            
             "slider_v2": [
                 "public/css/build/prod/tint-slider_v2.min.css",
             ], 
            
             "slideshow_v2": [
                 "public/css/build/prod/tint-slideshow_v2.min.css",
             ], 
            
             "square_photo_v2": [
                 "public/css/build/prod/tint-square_photo_v2.min.css",
             ], 
            
             "testimonials": [
                 "public/css/build/prod/tint-testimonials.min.css",
             ], 
            
             "ticker": [
                 "public/css/build/prod/tint-ticker.min.css",
             ], 
            
        }

    },
}