export const iconTypes = {
  facebook: 'facebook',
  brandfolder: 'brandfolder',
  twitter: 'twitter',
  instagram: 'instagram',
  instagram_business: 'instagram',
  youtube: 'youtube',
  linkedin: 'linkedin',
  pinterest: 'pinterest',
  hootsuite: 'hootsuite',
  ftp: 'cloud-check',
  spark: 'webex',
  webex: 'webex',
  slack: 'slack',
  magento: 'magento',
  shopify: 'shopify',
  woocommerce: 'woocommerce',
  salesforce_commerce_cloud: 'salesforce',
  bigcommerce: 'bigcommerce',
  request: 'request',
  csv: 'upload',
  upload: 'upload',
  custom: 'custom',
  manual: 'manual',
  adobe_experience_manager: 'adobe',
  bynder: 'bynder',
  dropbox: 'dropbox',
  google_drive: 'google_drive',
  hubspot: 'hubspot',
  mailchimp: 'mailchimp',
  salesforce_marketing_cloud: 'salesforce',
  widen_collective: 'widen',
  tumblr: 'tumblr',
  flickr: 'flickr',
  sms: 'sms',
  googleNews: 'googleNews',
  google_news: 'googleNews',
  experienceBuilder: 'experienceBuilder',
  reviews: 'reviews',
  publicPosting: 'publicPosting',
  rss: 'rss',
  tint_logo_white: 'tint_logo_white',
  adobe: 'adobe',
  tiktok: 'tiktok',
  objectsColumn: 'objects_column',
  zapier: 'zapier',
  rectangleVerticalHistory: 'rectangle_vertical_history',
  s3: 's3',
  http: 'cloud-check',
  bazaarvoice: 'bazaarvoice',
};
