import { Config } from '../../../public/js/react/utils/config';

const V1_PERSONLIZATION_TOP_LEVEL_FIELDS = [
  'user_id',
  'theme',
  'font_secondary',
  'fontsize_primary',
  'fontsize_secondary',
  'color_name',
  'color_namebar',
  'color_namebar_buttons',
  'color_hype',
  'position_name',
  'position_namebar',
  'bg_page_image',
  'image_logo',
  'profile_embed',
  'bg_image',
  'embed_display_profile',
  'embed_display_secondarynav',
  'embed_color_bodybg',
  'embed_mincolumnwidth',
  'embed_class_secondarynav',
  'embed_url',
  'sharebuttons',
  'custom_css',
  'show_author',
  'personalization_name',
  'public_add_post',
  'remove_duplicate_images',
  'popup_custom_css',
];

async function getDefaultPersonalization(tintSlug) {
  const url = `${Config.getEnvironmentVariable('API_URL')}/v2/tints/${tintSlug}?include=default_personalization`;
  return fetch(url).then(data => {
    return data.json().then(jsonData => {
      if (jsonData.included) {
        return jsonData.included.find(i => {
          return i.type == 'personalization';
        });
      }
    });
  });
}

async function getPersonalization(id) {
  return fetch(`${Config.getEnvironmentVariable('API_URL')}/v2/personalizations/${id}`).then(data => {
    return data.json().then(jsonData => {
      return jsonData.data;
    });
  });
}

export async function backportV2PersonlizationtoV1Format(tintSlug, personalizationId) {
  const defaultPersonalization = await getDefaultPersonalization(tintSlug);
  const personalizationOverride = personalizationId ? await getPersonalization(personalizationId) : undefined;
  const personalization = personalizationOverride ? personalizationOverride : defaultPersonalization;
  if (personalization) {
    return {
      theme: personalization['attributes']['theme'],
      personalization_id: personalization['id'],
      ...pick_keys(personalization['attributes']['data']['published'], V1_PERSONLIZATION_TOP_LEVEL_FIELDS),
      embed_display_profile: JSON.stringify(
        exclude_keys(personalization['attributes']['data']['published'], V1_PERSONLIZATION_TOP_LEVEL_FIELDS)
      ),
    };
  }
}

function pick_keys(object, keys) {
  return Object.keys(object)
    .filter(key => keys.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: object[key],
      };
    }, {});
}

function exclude_keys(object, keys) {
  return Object.keys(object)
    .filter(key => !keys.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: object[key],
      };
    }, {});
}
