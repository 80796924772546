import { jsFiles } from '../build/jsLinks';
import { cssFiles } from '../build/cssLinks';
import { Config } from '../../../public/js/react/utils/config';
import compiledTemplate from '../templates/index.handlebars';
import * as builtinThemes from '../../../application/config/themes.json';
import * as themes from '../../../themes/themes.json';
import { backportV2PersonlizationtoV1Format } from './personalization';

window.addEventListener('load', function() {
  const API_BASE_URL = Config.getEnvironmentVariable('API_URL');
  const urlParams = new URLSearchParams(document.location.search);
  const username = location.href.match(/(app\/legacy|t)\/([^?\/]+)/)[2];
  const currentHTMLPATH = location.href.match(/(app\/legacy|t)\/([^?]+)/)[1];
  const personalizationId = urlParams.get('personalization_id');
  const forceBundle = urlParams.get('forcebundle');
  const NEW_THEMES = [
    'new_classic',
    'new_atmosphere',
    'new_slider',
    'new_square_photo',
    'new_tall_photo',
    'new_gem',
    'new_news',
    'table',
    'email_template_grid',
    'email_template_collage',
  ];
  const noTrack = urlParams.get('notrack') || false;

  //it means that we loaded popup iframe
  if (username === 'popup') {
    return;
  }

  fetch(`${API_BASE_URL}/v1/profile/${username}?${urlParams.toString()}`).then(data => {
    data.json().then(async jsonData => {
      //if request failed, do nothing
      if (!jsonData || jsonData.error) {
        return;
      }

      const bootstrapData = {};
      const enabled = Object.keys(jsonData.enabled);
      bootstrapData['profile'] = JSON.parse(JSON.stringify(jsonData));
      if (!bootstrapData['profile']['theme']) {
        bootstrapData['profile']['theme'] = 'classic_v3';
      }
      var env = Config.isDevEnv() ? 'dev' : 'prod';
      var envName = Config.getEnvironment();
      var js = [];
      var css = [];
      var bundle = forceBundle ? 'bundled' : Config.isDevEnv() ? 'unbundled' : 'bundled';
      var themeInfo = builtinThemes.default;
      themeInfo['themes'] = themes.default;

      const v2Personalization = await backportV2PersonlizationtoV1Format(username, personalizationId);
      bootstrapData['profile'] = {
        ...bootstrapData['profile'],
        ...v2Personalization,
      };
      if (NEW_THEMES.includes(bootstrapData.profile.theme)) {
        window.location.href = `/tint/tint.html#${username}${document.location.search}`;
      } else {
        js = js.concat(jsFiles[bundle][env]['common']);
        js = js.concat(jsFiles[bundle][env][bootstrapData['profile']['theme']]);
        css = css.concat(cssFiles[bundle][env]['common']);
        css = css.concat(cssFiles[bundle][env][bootstrapData['profile']['theme']]);
        document.open();
        document.write(
          compiledTemplate({
            currentHtmlPath: currentHTMLPATH,
            bootstrapData: JSON.stringify(bootstrapData),
            enabled: JSON.stringify(jsonData.enabled),
            js: js,
            css: css,
            config: Config.getEnvironmentVariables(),
            isDev: Config.isDevEnv(),
            themeInfo: JSON.stringify(themeInfo),
            noTrack: noTrack,
            defaultPersonalizationId: v2Personalization?.personalization_id,
          })
        );
        document.close();
      }
    });
  });
});
