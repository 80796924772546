export const SocialFeedsSource = {
  TWITTER: 'twitter',
  INSTAGRAM_BUSINESS: 'instagram_business',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  RSS: 'rss',
  PUBLIC_POST: 'public_post',
  SMS: 'sms',
  EMAIL: 'email',
  USER_REVIEW: 'review_trackers',
  TUMBLR: 'tumblr',
  LINKED_IN: 'linkedin',
  FLICKR: 'flickr',
  GOOGLE_NEWS: 'google_news',
  SLACK: 'slack',
  HOOT_SUITE: 'hootsuite',
  TIK_TOK: 'tiktok',
  WEBEX: 'spark',
  EXPERIENCE_BUILDER: 'experience_builder',
  FORMS: 'forms',
  FORM_SUBMISSION: 'form_submission',
  INFLUENCER: 'influencer',
  CUSTOM: 'custom',
  DELETED: 'deleted',
  YOUTUBE: 'youtube',
  PINTEREST: 'pinterest',
  EXTERNALLY_SOURCED_POSTS: 'externally_sourced_posts',
  EXTERNALLY_SOURCED_POSTS_ACCOUNT: 'externally_sourced_posts_account',
  SCHEDULED_POSTS: 'scheduled_posts',
  REVIEWS_RATINGS: 'reviews_ratings',
  VESTA: 'mission',
  THREADS: 'threads',
  CREATOR_IQ: 'creator_iq',
  POWER_REVIEWS: 'power_reviews',
  BAZAARVOICE: 'bazaarvoice',
  RE_CAPTCHA: 're_captcha',
};

export const SocialFeedsTypes = {
  TWITTER_GEOCODE: 'geocode',
  FLICKR_HASHTAG: 'hashtag',
  FLICKR_USER: 'user',
  FORM: 'form',
  REVIEWS: 'reviews',
  POST: 'post',
  MENTIONED: 'mentioned',
  COMMENTS: 'comments',
};
